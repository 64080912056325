import React, { useState } from "react";
import fetch from "isomorphic-unfetch";

//ASSETS
import HeroImg from "../assets/contact-us.jpeg";

//COMPONENTS
import HeroSection from "../components/HeroSection";
import { email, phoneNumber, address } from "../shared";

const Contact = () => {

    const [form, setForm] = useState({
        fullname: "",
        telephone: "",
        email: "",
        message: "",
    });
    const [errors, setErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleChange = (event) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value,
        });
    };

    const createContactMessage = async () => {
        try {
            const res = await fetch("/send", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(form),
            });

            setForm({
                fullname: "",
                email: "",
                telephone: "",
                message: "",
            });

        } catch (error) {
            console.log(error);
        }
    };

    const validate = () => {
        let err = {};

        if (!form.fullname) {
            err.fullname = "A name is required";
        }
        if (!form.email) {
            err.email = "A company is required";
        }
        if (!form.telephone) {
            err.telephone = "An email address is required";
        }
        if (!form.message) {
            err.message = "A message is required";
        }

        return err;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let errs = validate();
        if (Object.keys(errors).length > 0) {
            setErrors(errs);
        } else {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute("6Lf36J4hAAAAAJAq1I3mnc5a8ymKNA6MJXpa5c7t", { action: 'submit' }).then(function (token) {
                    createContactMessage();
                });
            });
            setIsSubmitted(true);
        }
    };

    return (
        <div>
            <HeroSection img={HeroImg} title={"Contact Us"} />

            <div className="max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-6xl mx-auto px-4 py-8 md:py-24 sm:py-28 sm:px-6 lg:px-8">
                <div className="lg:grid lg:grid-cols-2 lg:gap-24">
                    <div>
                        <div className="mb-14">
                            <div className="w-6 h-1 bg-blue mb-6 rounded-lg"></div>
                            <h2 className="text-4xl font-extrabold text-darkGrey tracking-tight sm:text-4xl">
                                Get in touch
                            </h2>
                            <h3 className="mt-4 text-3xl text-darkGrey sm:mt-3">
                                Want to know more about MYMUP Digital?
                            </h3>
                            <p className="mt-8 text-lg text-mediumGrey sm:mt-3">
                                If you have any questions or queries regarding
                                MYMUP Digital, then please give us a call or
                                email on the details below.
                            </p>
                        </div>

                        <div
                            className="flex border border-lightGrey p-12 mb-7 text-lg"
                            style={{ height: "fit-content" }}
                        >
                            <div className="text-blue">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-12 w-12"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                    />
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                </svg>
                            </div>
                            <div className="text-mediumGrey ml-8">
                                {address}
                            </div>
                        </div>

                        <div
                            className="flex items-center border border-lightGrey p-12 text-lg"
                            style={{ height: "fit-content" }}
                        >
                            <div className="text-blue">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-12 w-12"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M16 3h5m0 0v5m0-5l-6 6M5 3a2 2 0 00-2 2v1c0 8.284 6.716 15 15 15h1a2 2 0 002-2v-3.28a1 1 0 00-.684-.948l-4.493-1.498a1 1 0 00-1.21.502l-1.13 2.257a11.042 11.042 0 01-5.516-5.517l2.257-1.128a1 1 0 00.502-1.21L9.228 3.683A1 1 0 008.279 3H5z"
                                    />
                                </svg>
                            </div>
                            <div className="text-blue hover:text-darkblue ml-8">
                                <p>
                                    <a href={`mailto:${email}`}>
                                        {email}
                                    </a>
                                </p>
                                <p>
                                    <a href={`telephone:${phoneNumber}`}>{phoneNumber}</a>
                                </p>
                            </div>
                        </div>
                    </div>

                    <form onSubmit={handleSubmit} className="mt-9">
                        <div className="flex justify-between space-x-10 mb-5">
                            <input
                                type="text"
                                name="fullname"
                                id="fullname"
                                value={form.fullname}
                                placeholder="Your name"
                                onChange={handleChange}
                                className="bg-lightGrey block w-full border-none sm:text-sm px-5 py-4"
                                required
                            />

                            <input
                                type="email"
                                name="email"
                                id="email"
                                value={form.email}
                                placeholder="Email address"
                                onChange={handleChange}
                                className="bg-lightGrey block w-full border-none sm:text-sm px-5 py-4"
                                required
                            />
                        </div>

                        <input
                            type="tel"
                            name="telephone"
                            id="telephone"
                            value={form.telephone}
                            aria-describedby="phone-description"
                            placeholder="Telephone number"
                            onChange={handleChange}
                            className="bg-lightGrey block w-full border-none sm:text-sm mb-5 px-5 py-4"
                            required
                        />

                        <textarea
                            id="message"
                            name="message"
                            value={form.message}
                            aria-describedby="message"
                            rows={4}
                            placeholder="Your message"
                            onChange={handleChange}
                            className="bg-lightGrey block w-full border-none sm:text-sm mb-5 px-5 py-4"
                            required
                        />

                        <button
                            type="submit"
                            className="px-10 py-4 border border-transparent text-sm font-bold rounded-sm shadow-sm uppercase text-darkGrey bg-yellow"
                        >
                            Send message
                        </button>
                        {isSubmitted && <p className="bg-green mt-8 p-2 w-fit">Message sent successfully. Our team will be in touch shortly.</p>}
                    </form>
                </div>
            </div>

            <div className="w-full">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2354.357209348901!2d-1.7813654339650054!3d53.83651029558324!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487be46dc030faad%3A0x82befd047476d87d!2sShipley%20BD17%207TD!5e0!3m2!1sen!2suk!4v1661249524506!5m2!1sen!2suk" title="map" width="" height="" style={{ border: '0', width: '100%', height: '500px' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    );
};

export default Contact;
