import React, { useState, useRef, useEffect } from "react";

const FunctionsCard = ({
    img,
    alt,
    number,
    title,
    description,
    href,
    buttonColour,
    bgColour,
}) => {
    const [isHovered, setIsHovered] = useState(false);
    const [height, setHeight] = useState(0);
    const totalHeight = useRef();

    useEffect(() => {
        setHeight(totalHeight.current.clientHeight)
    }, []);

    const cardContentHeight = height - 224;

    return (
        <div
            onMouseOver={() => setIsHovered(true)}
            onMouseOut={() => setIsHovered(false)}
            className="flex-1"
            ref={totalHeight} 
        >
            <div style={{ backgroundColor: isHovered ? bgColour : "#f1f1f1" }} className="h-full">
                <img
                    src={img}
                    alt={alt}
                    className="hidden md:h-56 md:w-full md:block"
                />
                <div className="px-10 py-8 h-full flex flex-col justify-between" style={{ height: cardContentHeight + 'px'}}>
                    <div>
                        <div
                            className="mb-2 text-3xl font-bold text-center"
                            style={{ color: isHovered ? "white" : bgColour }}
                        >
                            {number}
                        </div>
                        <h3
                            className="text-2xl text-center pb-4 sm:pb-10"
                            style={{ color: isHovered ? "white" : "#38373C" }}
                        >
                            {title}
                        </h3>
                        <div></div>
                        <p
                            className="pb-4 sm:pb-10"
                            style={{
                                color: isHovered ? "white" : "#87868a",
                            }}
                        >
                            {description}
                        </p>
                    </div>
                    
                    <a
                        href={href}
                        className={`flex justify-center px-4 py-4 border border-transparent text-xs font-medium rounded-sm shadow-sm uppercase text-white lg:px-10`}
                        style={{ backgroundColor: buttonColour }}
                    >
                        Learn more
                    </a>
                </div>
            </div>
        </div>
    );
};

export default FunctionsCard;
