import React from "react";

//ASSETS
import HeroImg from "../assets/about-us.jpg";

//COMPONENTS
import HeroSection from "../components/HeroSection";
import ContactSection from "../components/ContactSection";
import { email, phoneNumber, address } from "../shared";

const PrivacyPolicy = () => {
    return (
        <div>
            <HeroSection img={HeroImg} title={"Privacy Policy"} />

            <div className="max-w-6xl mx-auto px-4 py-28 sm:px-6 lg:px-8">
                <h2 className="text-4xl text-darkGrey font-extrabold my-8">
                    Our Privacy Policy
                </h2>

                <h3 className="text-2xl text-darkGrey font-extrabold my-8">
                    1. Introduction
                </h3>
                <p className="text-mediumGrey mb-4">
                    <span className="font-bold text-darkGrey">MYMUP Digital Ltd</span> and its subsidiaries are committed to safeguarding your privacy as a user 
                    of Our web application (the <span className="font-bold text-darkGrey">‘Application’</span>).
                </p>
                <p className="text-mediumGrey mb-4">
                    We process your personal data, complying to the Data Protection Act 2018 (the <span className="font-bold text-darkGrey">‘Act’</span>) and 
                    the General Data Protection Regulations (2016/679) (<span className="font-bold text-darkGrey">’GDPR’</span>).
                </p>
                <p className="text-mediumGrey mb-4">
                    This privacy policy (together with our terms of use and any documents referred to in it) 
                    identifies how we may hold and use information about you.
                </p>
                

                <h3 className="text-2xl text-darkGrey font-extrabold my-8">
                    2. How we use your personal data
                </h3>
                <p className="text-mediumGrey mb-4">
                    This policy sets out:
                </p>
                <ul className="text-mediumGrey mb-4 flex flex-col space-y-2">
                    <li>
                        &bull; the general categories of personal data we process;
                    </li>
                    <li>
                        &bull; the purposes for which we process that personal data; and
                    </li>
                    <li>
                        &bull; the legal basis for processing that personal data.
                    </li>
                </ul>
                <ul className="text-mediumGrey mb-4 flex flex-col space-y-2">
                    <li>
                        a) <span className="font-bold text-darkGrey">‘Account Data’</span> - This is information provided to us by the data controller when registering
                        for our services. Account Data includes your name, address, date of birth, email address 
                        and telephone number. Account Data is processed for the purposes of setting up, 
                        providing access to and operating your account on our Application.
                    </li>
                    <li>
                        b) <span className="font-bold text-darkGrey">‘Correspondence Data’</span> - This is information contained in or relating to any 
                        communication that you send to us. Correspondence Data may be processed for the 
                        purposes of communicating with you and recording our communications with you. 
                        <span className="font-bold text-darkGrey">‘Enquiry Data’</span> - This is the information you provide to us by contacting us in relation to 
                        the services we provide. Enquiry Data may be processed for the purposes of offering relevant  
                        services to you.
                    </li>
                    <li>
                        c) <span className="font-bold text-darkGrey">‘Personal Confidential Data’</span> – This is information linked to staff and their employment 
                        or information linked to apprentices who may be receiving education and support from 
                        MYMUP or its subsidiaries.
                    </li>
                    <li>
                        d) <span className="font-bold text-darkGrey">‘Profile Data’</span> - This is the information you provide through your user profile on our 
                        Application. This includes your name, address, email address, telephone number, profile 
                        picture, your gender, interests, written thoughts, reflections, personal opinion, 
                        photographs and documents. Profile Data may be processed for the purposes of 
                        enabling and monitoring your use of our Application and services.
                    </li>
                    <li>
                        e) <span className="font-bold text-darkGrey">‘Usage Data’</span> - This may include your IP address, geographical location, browser type 
                        and version, operating system, referral source, length of visit, page views and Application
                        navigation paths, as well as information about the timing, frequency and pattern of your 
                        service use. The source of the Usage Data is our analytics tracking system. This Usage 
                        Data may be processed for the purposes of analysing the use of the Application and 
                        services.
                    </li>
                    <li>
                        f) <span className="font-bold text-darkGrey">‘Well-being Data’</span> - This may include health records, referral information to other 
                        services and self-assessment scores. Well-being Data is processed to safeguard, protect 
                        or improve your well-being and otherwise as part of the provision of our services to you. 
                        The basis for this processing is legitimate interest as set out by the controller(s) OR where
                        the commissioner is an NHS organisation, processing is based on the exercise of official 
                        authority. Where we have reasonable grounds to believe that your well-being is at risk, we
                        will otherwise process Well-being Data in order to protect your vital interests.
                    </li>
                </ul>

                <h4 className="text-xl text-darkGrey font-extrabold my-6">
                    Sensitive personal data
                </h4>
                <p className="text-mediumGrey mb-4">
                    We may ask you to provide sensitive personal data when using our Application or services. We
                    will only collect your sensitive personal data in line with requirements to provide the services 
                    requested: fulfilling contractual obligations with Controllers.
                </p>
                <p className="text-mediumGrey mb-4">
                    Sensitive personal data includes information relating to:
                </p>
                <ul className="text-mediumGrey mb-4 flex flex-col space-y-2">
                    <li>
                        &bull; your ethnic origin;
                    </li>
                    <li>
                        &bull; your religious beliefs;
                    </li>
                    <li>
                        &bull; your physical or mental health or condition;
                    </li>
                    <li>
                        &bull; your genetics or biometrics; and
                    </li>
                    <li>
                        &bull; your sexual life.
                    </li>
                </ul>

                <h4 className="text-xl text-darkGrey font-extrabold my-6">
                    Processing personal data out of a necessity and/or legal obligation
                </h4>
                <p className="text-mediumGrey mb-4">
                    We may process any of your personal data where it is:
                </p>
                <ul className="text-mediumGrey mb-4 flex flex-col space-y-2">
                    <li>
                        &bull; necessary for the establishment, exercise or defence of legal claims, whether in court 
                        proceedings or otherwise. The basis for this processing is our legitimate interest in the 
                        protection of; and being able to assert, our legal rights, your legal rights and the legal 
                        rights of others; and/or
                    </li>
                    <li>
                        &bull; necessary for compliance with a legal obligation to which we are subject, or in order to
                        protect your vital interests or the vital interests of another natural person.
                    </li>
                </ul>

                <h4 className="text-xl text-darkGrey font-extrabold my-6">
                    Personal data provided by third parties
                </h4>
                <p className="text-mediumGrey mb-4">
                    Occasionally we may receive personal data about you from third parties, which we will add to the
                    data we already hold about you in order to help us deliver and improve our service to you.
                </p>

                <h4 className="text-xl text-darkGrey font-extrabold my-6">
                    Personal data about other individuals
                </h4>
                <p className="text-mediumGrey mb-4">
                    Please do not supply any other person’s personal data to us, unless we expressly ask you to 
                    do so.
                </p>

                <h4 className="text-xl text-darkGrey font-extrabold my-6">
                    Children’s personal data
                </h4>
                <p className="text-mediumGrey mb-4">
                    We will only process data for individuals who are under the age of 16 where consent is given 
                    or authorised by the holder of parental responsibility to the relevant controller(s).
                </p>

                <h3 className="text-2xl text-darkGrey font-extrabold my-8">
                    3. Who your information might be shared with
                </h3>
                <p className="text-mediumGrey mb-4">
                    We may disclose your personal data:
                </p>
                <ul className="text-mediumGrey mb-4 flex flex-col space-y-2">
                    <li>
                        &bull; to other companies within our group (including any subsidiaries) where those companies
                        help provide a service to you;
                    </li>
                    <li>
                        &bull; This includes:
                        <ul>
                            <li>
                                (a) <span className="font-bold">Your referrer</span>: being the person or organisation that referred you to our Application 
                                for the purpose of you receiving access to education, early well-being support, 
                                mental health promotion and self-care; and
                            </li>
                            <li>
                                (b) <span className="font-bold">Education, Employment, Health and Social Care Professionals</span>: for the purpose of 
                                tailoring and recording support and meeting your well-being needs.
                            </li>
                        </ul>
                    </li>
                    <li>
                        &bull; to anyone that you grant access, via your account login;
                    </li>
                    <li>
                        &bull; to protect your vital interests or those of another where we have a legitimate concern
                        about your or their safety; and
                    </li>
                    <li>
                        &bull; to law enforcement agencies in connection with any investigation to help prevent 
                        unlawful activity or otherwise in compliance with a legal obligation to which We are 
                        subject.
                    </li>
                </ul>

                <h3 className="text-2xl text-darkGrey font-extrabold my-8">
                    4. Marketing
                </h3>
                <p className="text-mediumGrey mb-4">
                    For service improvement reasons, we may use your email address; provided as part of your 
                    Account Data and Enquiry Data, to send email marketing communications about:
                </p>
                <ul className="text-mediumGrey mb-4 flex flex-col space-y-2">
                    <li>
                        &bull; new interventions; and
                    </li>
                    <li>
                        &bull; new developments to the Application.
                    </li>
                </ul>
                <p className="text-mediumGrey mb-4">
                    You will be asked to expressly agree in advance to our use of your personal information for 
                    marketing purposes.
                </p>
                <p className="text-mediumGrey mb-4">
                    You may opt out or instruct us at any time not to process your personal information for 
                    marketing purposes.
                </p>

                <h3 className="text-2xl text-darkGrey font-extrabold my-8">
                    5. Keeping your data secure
                </h3>
                <p className="text-mediumGrey mb-4">
                    We will use technical and organisational measures to safeguard your personal data (and
                    in line with Data Security & Protection Toolkit requirements), for example;
                </p>
                <ul className="text-mediumGrey mb-4 flex flex-col space-y-2">
                    <li>
                        &bull; Access to your account is controlled by a password and user name that are unique to
                        you;
                    </li>
                    <li>
                        &bull; We store your personal data on secure servers; and
                    </li>
                    <li>
                        &bull; Additional security measures as required.
                    </li>
                </ul>
                <p className="text-mediumGrey mb-4">
                    If you have any particular concerns about your information, please contact Us using Our contact
                    details below.
                </p>

                <h4 className="text-xl text-darkGrey font-extrabold my-6">What can I do to keep my information safe?</h4>
                <p className="text-mediumGrey mb-4">
                    You can go to Get Safe Online on how to protect your information and your computers and 
                    devices against fraud, identity theft, viruses and many other online problems, please visit 
                    <a href="www.getsafeonline.org" target="_blank">www.getsafeonline.org</a>. 
                    Get Safe Online is supported by HM Government and leading businesses.
                </p>

                <h3 className="text-2xl text-darkGrey font-extrabold my-8">
                    6. Retaining and deleting personal data
                </h3>
                <p className="text-mediumGrey mb-4">
                    The personal data that we process will not be kept for longer than is necessary.
                </p>
                <p className="text-mediumGrey mb-4">
                    We will retain your personal data for 1 year from the closure of your account, at the end of 
                    which it will be deleted from Our systems   
                </p>
                <p className="text-mediumGrey mb-4">
                    In some instances, your personal data may be retained for a longer period (this will be based on 
                    contract requirements with commissioners). This includes:
                </p>
                <ul className="text-mediumGrey mb-4 flex flex-col space-y-2">
                    <li>
                        &bull; where you seek re-referral to Our service;
                    </li>
                    <li>
                        &bull; where the data controller determines a longer retention period is required (your data
                        controller will inform you of this);
                    </li>
                    <li>
                        &bull; where you are Looked After and/or Leaving Care;
                    </li>
                    <li>
                        &bull; where necessary for us to defend or bring any actual or contemplated legal proceedings;
                    </li>
                    <li>
                        &bull; where there are; or reasonably may be, child or adult protection or safeguarding 
                        concerns, or otherwise in order to protect your vital interests or the vital interests of 
                        another natural person.
                    </li>
                </ul>

                <h3 className="text-2xl text-darkGrey font-extrabold my-8">
                    7. Cookies
                </h3>
                <p className="text-mediumGrey mb-4">
                    We use cookies as set out in Our cookies policy.
                </p>

                <h3 className="text-2xl text-darkGrey font-extrabold my-8">
                    8. Changes to the privacy policy
                </h3>
                <p className="text-mediumGrey mb-4">
                    We may change this privacy policy from time to time by publishing a new version on our 
                    Application.
                </p>
                <p className="text-mediumGrey mb-4">
                    You should check this policy occasionally to ensure you are aware of the most recent version 
                    that will apply each time you access this Application.
                </p>
                <p className="text-mediumGrey mb-4">
                    We may also notify you of changes to this policy by email or through messages displayed on
                    your profile and/or Our Application.
                </p>

                <h3 className="text-2xl text-darkGrey font-extrabold my-8">
                    9. Your rights
                </h3>
                <p className="text-mediumGrey mb-4">
                    You have the following rights in respect of the personal data we hold about you:
                </p>
                <ul className="text-mediumGrey mb-4 flex flex-col space-y-2">
                    <li>
                        <span className="font-bold text-darkGrey">(a) Right of access</span>
                        <br/>
                        You have the right to request a copy of the personal data which we hold about you. You 
                        may also request details of the purposes of the processing, the categories of personal 
                        data concerned and the recipients of the personal data. Requests should initially go to the 
                        data controller.
                    </li>
                    <li>
                        <span className="font-bold text-darkGrey">(b) Right to rectification</span>
                        <br/>
                        You have the right to have any inaccurate personal data about you rectified and to have
                        any incomplete personal data about you completed.
                    </li>
                    <li>
                        <span className="font-bold text-darkGrey">(c) Right to erasure</span>
                        <br/>
                        In certain circumstances, you have the right to request that we delete personal data held
                        about you, free of charge and without undue delay.
                        Those circumstances include:
                        <ul>
                            <li>
                                &bull; where personal data is no longer necessary in relation to the purposes for which they
                                were processed;
                            </li>
                            <li>
                                &bull; the processing is for direct marketing purposes; and/or
                            </li>
                            <li>
                                &bull; the personal data has been unlawfully processed.
                            </li>
                        </ul>
                        Where data has been lawfully processed and is integral to the data controllers processing, 
                        erasure may not be granted.
                    </li>
                    <li>
                        <span className="font-bold text-darkGrey">(d) Right to restrict processing of your personal data</span>
                        <br/>
                        You have the right to ask us not to process your personal data where:
                        <ul>
                            <li>
                                &bull; the accuracy of the personal data is contested;
                            </li>
                            <li>
                                &bull; processing is unlawful and you do not wish for the personal data to be erased; and
                            </li>
                            <li>
                                &bull; we no longer need the personal data for the purposes of our processing, but where
                                you require the data for the establishment, exercise or defence of legal claims.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span className="font-bold text-darkGrey">(e) Right to object to processing of your personal data</span>
                        <br/>
                        You have the right to object to our processing of your personal data:
                        <ul>
                            <li>
                                &bull; on grounds related to your situation. We will stop processing your personal data unless
                                we have a legitimate ground for processing which overrides your interests or rights;
                            </li>
                            <li>
                                &bull; where processing is for direct marketing purposes, including profiling to the extent that it
                                is related to such direct marketing.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span className="font-bold text-darkGrey">(f) Right to Data Portability</span>
                        <br/>
                        Where the legal basis for our processing of your personal data is consent, and such 
                        processing is carried out by automated means, you have the right to receive your personal 
                        data from us in a structured, commonly used and machine-readable format. However, this 
                        right does not apply where it would adversely affect the rights and freedoms of others.
                    </li>
                    <li>
                        <span className="font-bold text-darkGrey">(g) Right to Withdraw Consent</span>
                        <br/>
                        Where the legal basis for our processing of your personal data is consent, you have the
                        right to withdraw that consent at any time. Withdrawal will not affect the lawfulness of
                        processing before the withdrawal.
                    </li>
                    <li>
                        <span className="font-bold text-darkGrey">(h) Right to Complain</span>
                        <br/>
                        If you consider that our processing of your personal information infringes data protection
                        laws, you have a legal right to lodge a complaint with a supervisory authority responsible
                        for data protection.
                    </li>
                </ul>

                <h3 className="text-2xl text-darkGrey font-extrabold my-8">
                    10. Exercising rights in relation to your personal data
                </h3>
                <p className="text-mediumGrey mb-4">
                    You can exercise your rights at any time by contacting us using the contact details below. 
                    Your request will then be shared with the Controller.
                </p>
                <p className="text-mediumGrey mb-4">
                    Following a request by you, we may request account information and/or proof of your 
                    identity and address in order to help us identify you (for example, a copy of your driving 
                    licence or passport and a recent utility or credit card bill.
                </p>
                <p className="text-mediumGrey mb-4">
                    Where you request a copy of the personal data which we hold, the first copy will be provided 
                    free of charge, however we may charge a small administration fee for additional requests if 
                    made within 12 months of the first request.
                </p>

                <h3 className="text-2xl text-darkGrey font-extrabold my-8">
                    11. Our details and how to contact us
                </h3>
                <p className="text-mediumGrey mb-4">
                    MYMUP Digital Ltd, a community interest company registered in England and Wales under 
                    Company Number: 11318914.
                </p>
                <p className="text-mediumGrey mb-4">
                    Our registered office address and principal place of business and Postal Address is:
                </p>
                <p className="text-mediumGrey mb-4">
                {address}
                </p>
                <p className="text-mediumGrey mb-4">
                    You can contact Us:
                </p>
                <ul className="text-mediumGrey mb-4 flex flex-col space-y-2">
                    <li>
                        &bull; by post, using Our Postal Address;
                    </li>
                    <li>
                        &bull; by email to {email}; or
                    </li>
                    <li>
                        &bull; by telephone on {phoneNumber}.
                    </li>
                </ul>

                <h3 className="text-2xl text-darkGrey font-extrabold my-8">
                    12. Data Protection Officer
                </h3>
                <p className="text-mediumGrey mb-4">
                    Our nominated Data Protection Officer for the purposes of the Act and the GDPR is GR 
                    Governance & Consultancy Services.
                </p>
                <p className="text-mediumGrey mb-4">
                    You can contact Our Data Protection Offer:
                </p>
                <ul className="text-mediumGrey mb-4 flex flex-col space-y-2">
                    <li>
                        &bull; by post, using Our Postal Address;
                    </li>
                    <li>
                        &bull; by email to {email}; or
                    </li>
                    <li>
                        &bull; by telephone on {phoneNumber}.
                    </li>
                </ul>
            </div>

            <ContactSection />
        </div>
    );
};

export default PrivacyPolicy;
