import React, { useState, useEffect } from "react";

const Contact = () => {

    const [form, setForm] = useState({
        fullname: "",
        telephone: "",
        email: "",
        message: "",
    });
    const [errors, setErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleChange = (event) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value,
        });
    };

    const createContactMessage = async () => {
        try {
            const res = await fetch("/send", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(form),
            });

            setForm({
                fullname: "",
                email: "",
                telephone: "",
                message: "",
            });

        } catch (error) {
            console.log(error);
        }
    };

    const validate = () => {
        let err = {};

        if (!form.fullname) {
            err.fullname = "A name is required";
        }
        if (!form.email) {
            err.email = "A company is required";
        }
        if (!form.telephone) {
            err.telephone = "An email address is required";
        }
        if (!form.message) {
            err.message = "A message is required";
        }

        return err;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let errs = validate();
        if (Object.keys(errors).length > 0) {
            setErrors(errs);
        } else {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute("6Lf36J4hAAAAAJAq1I3mnc5a8ymKNA6MJXpa5c7t", { action: 'submit' }).then(function (token) {
                    createContactMessage();
                });
            });
            setIsSubmitted(true);
        }
    };

    return (
        <div className="flex flex-col bg-lightGrey md:flex-row">
            <div className="flex-1">
                <div className="h-96 md:h-full">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2354.357209348901!2d-1.7813654339650054!3d53.83651029558324!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487be46dc030faad%3A0x82befd047476d87d!2sShipley%20BD17%207TD!5e0!3m2!1sen!2suk!4v1661249524506!5m2!1sen!2suk" title="map" width="" height="" style={{ border: '0', width: '100%', height: '100%' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>

            <div className="flex-1 relative py-16 px-4 sm:py-24 sm:px-6 lg:px-28 lg:max-w-6xl lg:mx-auto">
                <div className="mx-auto lg:mx-0">
                    <div className="w-6 h-1 bg-blue mb-6 rounded-lg"></div>
                    <h2 className="text-3xl font-extrabold text-darkGrey tracking-tight sm:text-4xl">
                        Get in touch
                    </h2>
                    <h3 className="mt-4 text-lg text-darkGrey sm:mt-3">
                        Want to know more?
                    </h3>
                    <p className="mt-4 text-mediumGrey sm:mt-3">
                        Fill in the form below and one of our team will be in
                        touch.
                    </p>
                    <form onSubmit={handleSubmit} className="mt-9">
                        {isSubmitted && (
                            <div className="mt-4 text-lg">
                                Your message has been submitted.
                            </div>
                        )}
                        <div className="flex justify-between space-x-10 mb-5">
                            <input
                                type="text"
                                name="fullname"
                                id="fullname"
                                placeholder="Your name"
                                value={form.fullname}
                                onChange={handleChange}
                                className="block w-full border-none sm:text-sm px-5 py-4"
                                required
                            />

                            <input
                                type="email"
                                name="email"
                                id="email"
                                placeholder="Email address"
                                value={form.email}
                                onChange={handleChange}
                                className="block w-full border-none sm:text-sm px-5 py-4"
                                required
                            />
                        </div>

                        <input
                            type="tel"
                            name="telephone"
                            id="telephone"
                            aria-describedby="phone-description"
                            placeholder="Phone number"
                            value={form.telephone}
                            onChange={handleChange}
                            className="block w-full border-none sm:text-sm mb-5 px-5 py-4"
                            required
                        />

                        <textarea
                            id="message"
                            name="message"
                            aria-describedby="message"
                            rows={4}
                            placeholder="Your message"
                            value={form.message}
                            onChange={handleChange}
                            className="block w-full border-none sm:text-sm mb-5 px-5 py-4"
                            required
                            error={
                                errors.message
                                    ? { content: "Please enter a message", pointing: "below" }
                                    : null
                            }
                        />
                        <button
                            type="submit"
                            className="px-10 py-4 border border-transparent text-sm font-bold rounded-sm shadow-sm uppercase text-darkGrey bg-yellow"
                        >
                            Send message
                        </button>
                        {isSubmitted && <p className="bg-green mt-8 p-2 w-fit">Message sent successfully. Our team will be in touch shortly.</p>}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Contact;
