import React from "react";

const TeamCard = ({ img, alt, name, jobRole }) => {

    return (
        <div className="p-2">
            <div className="bg-white border border-lightGrey hover:shadow-md">
                <img src={img} alt={alt} className="w-full p-2" />
                <div className="px-4 py-4 lg:py-8 text-center transition duration-600 ease">
                    <p className="text-darkGrey text-lg font-bold">{name}</p>
                    <p className="text-darkGrey text-sm lg:text-base">
                        {jobRole}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TeamCard;
