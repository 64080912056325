import React, { useState, useEffect } from "react";

//ASSETS
import HeroImg from "../assets/about-us.jpg";

//COMPONENTS
import HeroSection from "../components/HeroSection";
import Commissioners from "../components/clients/Commissioners";
import Mhst from "../components/clients/Mhst";
import VoluntarySector from "../components/clients/Voluntary-sector";
// import ComparisonTable from "../components/clients/ComparisonTable";
import ContactSection from "../components/ContactSection";

const Clients = () => {
  // const isMiddleSection = true

  const [isCommissionersActive, setIsCommissionersActive] = useState(false);
  const [isMhstActive, setIsMhstActive] = useState(false);
  const [isVoluntarySectorActive, setIsVoluntarySectorActive] = useState(false);

  const openCommissioners = () => {
    setIsCommissionersActive(!isCommissionersActive);
    setIsMhstActive(false);
    setIsVoluntarySectorActive(false);
  };

  const openMhst = () => {
    setIsCommissionersActive(false);
    setIsMhstActive(!isMhstActive);
    setIsVoluntarySectorActive(false);
  };

  const openVoluntarySector = () => {
    setIsCommissionersActive(false);
    setIsMhstActive(false);
    setIsVoluntarySectorActive(!isVoluntarySectorActive);
  };

  useEffect(() => {
    const idClient = window.location.hash;

    console.log(idClient);

    if (idClient === "#commissioners") {
      openCommissioners();
    } else if (idClient === "#mhst") {
      openMhst();
    } else if (idClient === "#voluntary-sector") {
      openVoluntarySector();
    }
  }, []);

  return (
    <div>
      <HeroSection img={HeroImg} title={"Clients"} />

      <div className="px-4 py-12 sm:px-6 lg:px-8">
        <div className="text-center text-3xl mb-8">Who Are You?</div>

        <div className="flex justify-between max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-6xl mx-auto ">
          <button
            onClick={() => openCommissioners()}
            id="commissioners"
            className="px-12 py-6 text-2xl bg-lightGrey hover:text-white hover:bg-pink"
          >
            Commissioners
          </button>
          <button
            onClick={() => openMhst()}
            id="mhst"
            className="px-12 py-6 text-2xl bg-lightGrey hover:text-white hover:bg-purple"
          >
            MHST
          </button>
          <button
            onClick={() => openVoluntarySector()}
            id="voluntary-sector"
            className="px-12 py-6 text-2xl bg-lightGrey hover:text-white hover:bg-orange"
          >
            Voluntary Sector
          </button>
        </div>
      </div>

      {isCommissionersActive && <Commissioners />}

      {isMhstActive && <Mhst />}

      {isVoluntarySectorActive && <VoluntarySector />}

      {/* <ComparisonTable/> */}

      <ContactSection />
    </div>
  );
};

export default Clients;
