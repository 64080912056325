import React from "react";

const content = [
  {
    name: "Commissioners",
    href: "/clients#commissioners",
    bgColour: "pink",
  },
  {
    name: "MHST",
    href: "/clients#mhst",
    bgColour: "purple",
  },
  {
    name: "Voluntary Sector",
    href: "/clients#voluntary-sector",
    bgColour: "orange",
  },
];
const MainCustomers = () => {

  return (
    <div className="bg-lightGrey">
      <div className="max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-6xl mx-auto px-4 py-20 sm:px-6 lg:px-8 sm:py-28">
        <div className="flex justify-between items-center">
          <div>
            <h2 className="text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
              Who are you?
            </h2>
            <div className="mt-6">
              <a
                href="/clients"
                className="text-base font-medium text-pink hover:text-darkGrey"
              >
                Who uses MYMUP?{"  "}&rarr;
              </a>
            </div>
          </div>
          <div className="mt-12 grid grid-cols-2 gap-0.5 md:mt-0 md:grid-cols-3">
            {content.map((item) => (
              <div
                key={item.name}
                className={`col-span-1 flex justify-center py-8 px-8 text-lg bg-gray-50 hover:text-white hover:bg-${item.bgColour}`}
              >
                <a href={item.href}>{item.name}</a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainCustomers;
