import React from "react";

//ASSETS
import SuzanneImg from "../../assets/suzanne-elster.jpg";
import AndrewImg from "../../assets/andrew-claypole.jpg";
import DanielImg from "../../assets/daniel-carroll.jpg";
import RossImg from "../../assets/ross-elliot.jpg";
import KaelaImg from "../../assets/kaela-shuttleworth.jpg";
import MichaelImg from "../../assets/michael-davis.jpg";
import RuthImg from "../../assets/ruth-hirst.jpg";
import KatieImg from "../../assets/katie-baxter.jpg";

//COMPONENTS
import TeamCard from "./TeamCard";

const teamMembers = [
  {
    name: "Suzanne Elster",
    img: SuzanneImg,
    alt: "Suzanne Elster",
    jobRole: "Operations Director",
  },
  {
    name: "Andrew Claypole",
    img: AndrewImg,
    alt: "Andrew Claypole",
    jobRole: "IT Manager",
  },
  {
    name: "Daniel Carroll",
    img: DanielImg,
    alt: "Daniel Carroll",
    jobRole: "Media/Communications & Content Development",
  },
  {
    name: "Ross Elliot",
    img: RossImg,
    alt: "Ross Elliot",
    jobRole: "Account Manager & Content Development",
  },
  {
    name: "Kaela Shuttleworth",
    img: KaelaImg,
    alt: "Kaela Shuttleworth",
    jobRole: "Director",
  },
  {
    name: "Michael Davis",
    img: MichaelImg,
    alt: "Michael Davis",
    jobRole: "Content Development",
  },
  {
    name: "Ruth Hirst",
    img: RuthImg,
    alt: "Ruth Hirst",
    jobRole: "Operations Manager",
  },
  {
    name: "Katie Baxter",
    img: KatieImg,
    alt: "Katie Baxter",
    jobRole: "Senior Account Manager",
  },
];

const MeetTeam = () => {
  return (
    <div className="bg-lightGrey">
      <div className="max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-6xl mx-auto px-4 py-20 sm:px-6 lg:px-8 sm:py-28">
        <h2 className="text-center text-4xl sm:text-5xl text-darkGrey mb-8 sm:mb-12">
          Meet the Team
        </h2>
        <div className="grid sm:grid-cols-2 gap-2 lg:grid-cols-3">
          {teamMembers.map((item) => (
            <TeamCard
              key={item.name}
              img={item.img}
              alt={item.alt}
              name={item.name}
              jobRole={item.jobRole}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MeetTeam;
