//If those informations needs to be changed, it just here and nowhere else!

export const phoneNumber = "01274 897714";
export const email = "info@mymup.org";
export const address = (
    <ul>
        <li>MYMUP Digital</li> 
        <li>Regus 1st Floor East</li>  
        <li>Suite</li> 
        <li>The Waterfront</li> 
        <li>Shipley</li> 
        <li>West Yorkshire</li> 
        <li>BD17 7TD</li>
    </ul>
);